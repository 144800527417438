import * as React from 'react';

import Layout from '../components/layout/layout';

export default function NotFoundPage() {
  return (
    <Layout title="Page Not Found">
      <h1>404 Page</h1>
    </Layout>
  );
}
